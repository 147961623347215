<template>
<div style="text-align: center">
<lottie-player src="https://assets10.lottiefiles.com/packages/lf20_lfirbva8.json"  background="transparent"  speed="1"  style="width: 100%; height: 300px;"  loop autoplay></lottie-player>
Downloading quran data... 

</div>
</template>
<script>
export default {

}
</script>


<style>

</style>